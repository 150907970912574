.dialog_dark.News_Dialog {
  background-color: rgba(21, 40, 73, 0.6);
}

.dialog_light.News_Dialog {
  background-color: rgba(255, 255, 255, 0.6);
}

.News_Dialog {
  backdrop-filter: blur(4px);
  width: 90% !important;
  max-width: 500px;
}

.News_Dialog .p-dialog-header {
  background-color: transparent;
  padding: 50px 10px 0px !important;
}

.News_Dialog .p-dialog-content {
  background-color: transparent;
}

.News_logo {
  position: absolute;
  top: -29px;
  left: calc((500px - 58px) / 2);
  background: linear-gradient(#5d98ff, #152849);
  border-radius: 50%;
  padding: 4px 3px 4px 3px;
}

.News_logo > i {
  color: white !important;
  font-size: 30px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 10px;
}

.News_Dialog p.description {
  color: black;
}

.News_Dialog .p-dialog-header-icons {
  position: absolute;
  right: 10px;
  top: 15px;
}

.dialog_dark.News_Dialog *:not(input) {
  color: white;
}

.dialog_light.News_Dialog *:not(input, .p-button-label) {
  color: #152849;
}

.News_Dialog .p-button-label {
  color: white;
}

.News_Dialog h1.dialog_title {
  width: 100%;
  color: #5d98ff !important;
  font-size: 32px;
}

.news_button > .News_logo {
  position: relative;
  top: 0;
  left: 0;
}

.news_button:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.news_button {
  transition: 0.2s;
}

.news_input {
  width: calc(100% - 108px);
}
