:root {
  --site-main-color: #f05537;
  --site-text-black: #565a6a;
  --site-color-white: #ffffff;
  --site-menu-font-size: 16px;
  --site-menu-icon-size: 13px;
}

* {
  font-family: "Nunito", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.date_search .p-inputtext {
  padding: 12px;
}

.search_date .p-inputtext.p-component {
  border: none;
  width: 100%;
}

.search_date .p-inputtext.p-component:enabled:focus {
  box-shadow: none !important;
  border: none !important;
}

.search_date .p-inputtext:enabled:hover {
  box-shadow: none !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .search_date .p-inputtext {
    padding: 0.5rem !important;
  }
}

.city_search .p-inputtext {
  padding: 10px;
}

.city_search.p-dropdown {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.city_search.p-dropdown:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.city_search.p-dropdown:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.city_search.p-dropdown:not(.p-disabled):hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.city_search.p-dropdown:not(.p-disabled):focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.nlp_search.p-inputtext {
  padding: 10px;
}

.active_category {
  box-shadow: 2px 2px 4px gray;
  transition: 0.4s;
}

.dropbtn {
  background-color: var(--site-color-white);
  color: var(--site-text-black);
  font-size: var(--site-menu-font-size);
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dropbtn > span {
  margin-right: 5px;
}

.dropbtn > i {
  font-size: var(--site-menu-icon-size);
  transition: 0.2s;
}

.dropdown:hover i {
  transform: rotateZ(180deg);
  transition: 0.2s;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transform: translateY(10px);
  transition: 0.8s;
  visibility: hidden;
}

.dropdown-content a {
  color: var(--site-text-black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 0.8s;
}
.dropdown:hover .dropbtn {
  background-color: var(--site-color-white);
}

button {
  border-radius: 4px !important;
}
button > span {
  font-weight: 500 !important;
}

.signin button {
  padding: 5px 15px !important;
}

.signin {
  gap: 10px;
  display: flex;
}

.p-button.p-button-icon-only {
  padding: 5px 15px !important;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
  color: #086af9 !important;
}
